import axios from 'axios';
import { Api } from './funwoo.api';
import { isBrowser } from '@/utils/isBrowser';

const instance = axios.create({
  baseURL: isBrowser() ? `${window.location.origin}/rewrite/funwoo-apis` : process.env.FUNWOO_APIS_URI!,
});

const FunwooAPI = new Api();
FunwooAPI.instance = instance;

export * from './funwoo.api';
export default FunwooAPI;
